.p,
p {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-xxs {
  font-size: 0.65rem !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-break {
  word-break: break-word !important;
}

.text-primary {
  color: #fdb42b !important;
}

.text-secondary {
  color: #8392ab !important;
}

.text-success {
  color: #82d616 !important;
}

.text-info {
  color: #6a8cfb !important;
}

.text-warning {
  color: #fbcf33 !important;
}

.text-danger {
  color: #ea0606 !important;
}

.text-light {
  color: #e9ecef !important;
}

.text-dark {
  color: #344767 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #67748e !important;
}

.text-muted {
  color: #6c757d !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-light {
  font-family: "Birzia-Light", sans-serif;
}

.font-weight-medium {
  font-family: "Birzia-Medium", sans-serif;
}

.font-weight-bold {
  font-family: "Birzia-Bold", sans-serif;
}

.font-weight-black {
  font-family: "Birzia-Black", sans-serif;
}
