*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  direction: rtl;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    text-decoration: none;
    color: inherit;
  }
}

input,
textarea {
  // caret-color: var(--dibble-yellow);

  &:hover,
  &:focus,
  &:active,
  &:visited {
    outline: none !important;
  }
}

html {
  font-size: 1rem;
}

body {
  box-sizing: border-box;
  background: #f8f9fa;
  font-family: "Birzia-Light", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
span {
  padding: 0 !important;
  margin: 0 !important;
}
.img-thumbnail {
  border: 0;
}

.card {
  border: 0;
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  margin: 0;
  padding: 0;
}

input[type="radio"] {
  cursor: pointer;
}

input[type="checkbox"] {
  cursor: pointer;
}

.input-normal {
  border-color: #ced4da; /* Default border color */
}

.input-error {
  border-color: #dc3545; /* Red color for errors */
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b8bec3 !important;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b8bec3 !important;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b8bec3 !important;
}

.active_screen {
  background: green;
  width: 10px;
  height: 10px;
  border-radius: 50px;
}
.pending_screen {
  width: 20px;
  height: 20px;
  // border-radius: 50px;
}
.table > :not(caption) > * > * {
  background-color: transparent !important; /* Reset background color for all table rows */
}

.table-responsive {
  .table {
    margin-bottom: 0;
    thead {
      background-color: #f2f2f2;

      th {
        color: #333;
        border: 1px solid #333;
        vertical-align: middle;
        white-space: nowrap;
      }
    }

    tbody {
      tr {
        border: 1px solid #333;

        &:nth-child(even) {
          background-color: #f2f2f2;
        }

        &:last-child {
          border: 1px solid #333;
        }
        td {
          padding: 12px;
          vertical-align: middle;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border: 1px solid #333;

          &:nth-child(2) {
            max-width: 350px;
          }
        }
      }
    }
  }
}

/* Tabs styling */
.custom-tabs .nav-item {
  width: 50%;
}

.custom-tabs .nav-link {
  text-align: center;
  border-radius: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  width: 100%;
  font-family: "Birzia-Medium", sans-serif;
  color: #000;
  border-radius: 0;
}

.nav-tabs .nav-link.active {
  color: #fdb42b;
  font-weight: 800;
  font-family: "Birzia-Bold", sans-serif;
  box-shadow: none;
}

.react-datepicker-popper {
  z-index: 9 !important;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  height: 100%;
  padding: 0 10px;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #fdb42b;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  content: "×";
}

.bg-weelz {
  background-color: #fdb42b !important;
}

.hover-link {
  cursor: pointer;
  text-decoration: none;
  transition: text-decoration 0.3s ease;
}

.hover-link:hover {
  text-decoration: underline;
}
