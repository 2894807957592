@font-face {
  font-family: "Birzia-Black";
  src: url("./fonts/Birzia-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Birzia-Bold";
  src: url("./fonts/Birzia-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Birzia-Light";
  src: url("./fonts/Birzia-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Birzia-Medium";
  src: url("./fonts/Birzia-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
