// ClientDetails.scss

.client-details-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-subtitle {
  font-size: 1rem;
  color: #6c757d;
}

.camera-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  border-width: 1px;
  border-color: #f2f2f2;
  background: #fff;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #f2f2f2;
    border-width: 1px;
    border-color: #f2f2f2;
    cursor: pointer;
  }
}
