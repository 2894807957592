/* Fixed Environment Header */
.env_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &.bg-yellow {
    background-image: linear-gradient(310deg, #fdb42b, #ffc65b);
    color: white;
  }

  &.bg-danger {
    background-color: #dc3545;
    color: white;
  }
}

/* Fixed Side Menu */
.side-menu {
  position: fixed;
  top: 40px;
  right: 0;
  height: calc(100vh - 40px);
  width: 16.6667%;
  background-color: #f8f9fa;
  overflow-y: auto;
  z-index: 998;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}

/* Content Area Adjustment */
.content-area {
  margin-right: 16.6667%;
  margin-top: 60px;
  padding: 1rem;
  width: calc(100% - 16.6667%);
  box-sizing: border-box;
  overflow-y: auto;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .side-menu {
    width: 100%;
    height: calc(100vh - 60px);
    right: 0;
    top: 60px;
  }

  .content-area {
    margin-right: 0;
    width: 100%;
    margin-top: 60px;
  }
}
