/* CustomTable.scss */
.custom-table-container {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;

  .table {
    margin-bottom: 0;
    thead {
      th {
        color: #333;
        border: none;
        border-bottom: 1px solid #e9ecef;
        vertical-align: middle;
        white-space: nowrap;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #e9ecef; /* This adds the border at the bottom */

        &:last-child {
          border-bottom: 0px solid #e9ecef;
        }

        td {
          padding: 12px;
          border: 1px solid #e9ecef; /* Add a border around each cell */
          vertical-align: middle;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:nth-child(2) {
            max-width: 350px;
          }
        }
      }
    }
  }
}

.table-row {
  transition: background-color 0.3s ease; /* Adds a smooth transition for the hover effect */
  border: 1px solid #e9ecef; /* Adds a border around each row */
}

.table-row:hover {
  background-color: #f2f2f2; /* Change this color to the desired hover color */
  cursor: pointer; /* Optional: change the cursor to a pointer */
}

.table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.table-btn {
  background-image: linear-gradient(310deg, #fdb42b, #ffc65b);
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background 0.3s ease;
  &:hover {
    background-image: linear-gradient(310deg, #ffc65b, #fdb42b);
  }
}

.create_station_modal--wrapper {
  display: flex;
  flex-direction: column;
}

.create_station_modal--icon {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &__vertical {
    border: 4px solid #000;
    border-radius: 0.5rem;
    font-size: 2rem;
    width: 60px;
    height: 40px;
  }
  &__horizontal {
    @extend .create_station_modal--icon__vertical;
    width: 40px;
    height: 60px;
  }
}

.pagination {
  display: flex;
  padding: 1rem 0 0 0;
  margin: 0;

  .page-item {
    &.active {
      .page-link {
        color: #fff;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .page-link {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.table-row {
  transition: background-color 0.3s ease; /* Adds a smooth transition for the hover effect */
}

.table-row:hover {
  background-color: #f2f2f2; /* Change this color to the desired hover color */
  cursor: pointer; /* Optional: change the cursor to a pointer */
}

.profile-container {
  display: flex;
  align-items: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.custom-regular-background {
  background-color: #f7bfe8 !important; /* Bright Red */
}

.error-color-class {
  background-color: #fff3cd;
}

.table-info-static {
  background-color: #e6fec9 !important;
}

.table-success-static {
  background-color: #9bd164 !important;
}

.custom-danger-background {
  background-color: #c0c0c0 !important;
}
.line-through {
  text-decoration: line-through !important;
}

.table {
  border-collapse: collapse; /* Ensures borders don't overlap */
}

.table td,
.table th {
  border: 1px solid #ddd; /* Adds a light gray border */
  padding: 8px; /* Adds spacing inside cells */
}
.react-datepicker-wrapper {
  width: 100%;
}

.nav-tabs .nav-link.active[href="#regular"] {
  background-color: #ffd700;
  border-color: #ffd700;
  color: #000;
}

.nav-tabs .nav-link.active[href="#public"] {
  background-color: #ffa500;
  border-color: #ffa500;
  color: #000;
}
.nav-tabs .nav-link.active[href="#all"] {
  background-color: #0d6efd;
  border-color: #0d6efd;
  color: #fff;
}
