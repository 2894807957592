.sidebar__logo {
  text-align: center;
  padding: 0.3rem;
  border-bottom: 1px solid #ddd;
  background-color: #f8f9fa;

  &__img {
    max-width: 100%;
    height: auto;
    max-height: 50px;
  }
}

.sidebar {
  position: relative;
  // min-height: 100vh;
  z-index: 100;
  height: inherit;
  // box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.1);

  &__item {
    display: flex;
    align-items: center;
    height: 100%;
    &:hover {
      color: #fdb42b;
    }

    &__icon {
      background: #fff;
      border-radius: 0.5rem;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 10px;
      box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12) !important;
    }
  }
}

.sidebar__footer {
  position: absolute;
  bottom: 20px;
}

.nav-item {
  padding-top: 0.675rem;
}

.nav-link {
  height: 4rem;
  font-weight: 600;
  border-radius: 0.5rem;
  color: #000;

  &:hover {
    color: #fdb42b;
  }
  &.active {
    background: #fff;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
    color: #fdb42b;

    .sidebar__item__icon {
      background-image: linear-gradient(310deg, #fdb42b, #ffc65b);
      color: #fff;
    }
  }
}
.custom-card-class .dropdown-menu {
  height: 48px !important;
  padding-top: 0px !important;
  width: 100% !important;
  position: relative;
}
.custom-vehicle-class{
  padding-top: 0px !important;
}
.drpdown:visited{
  color: unset;
}