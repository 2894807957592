.navbar {
  margin-top: 40px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0 10px;
  &-brand {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .nav-link {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 10px;
    color: #000;

    &.active {
      color: #fdb42b;
      gap: 10px;
    }

    svg {
      margin-right: 0.5rem;
    }
  }
  .custom-toggler {
    border-color: black;

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' fill='currentColor'><path stroke='rgba(0,0,0,1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
    }
  }
}

.custom-hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: white;
  border: 2px solid black;
  border-radius: 8px;
  cursor: pointer;
}

.custom-hamburger-icon {
  font-size: 1.5rem;
  color: black;
}

.custom-hamburger:hover {
  background-color: black;
  color: white;
}
