.avatar {
  position: relative;
  display: inline-block;

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: green;
    border-radius: 50%;
    z-index: 11;
  }
  &.online:before {
    background-color: green;
  }
  &.offline {
    img {
      opacity: 0.8;
    }
    &:before {
      z-index: 10;
      background-color: transparent;
      border-color: transparent;
      border: none;
    }
    &:after {
      content: " ";
      width: 10px;
      height: 10px;
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: #ff3c41;
      background-color: #ff3c41;
      border-radius: 50%;
      z-index: 11;
    }
  }
}

.invited {
  position: relative;
  display: inline-block;

  i {
    position: absolute;
    top: 0;
    right: -5px;
    width: 15px;
    height: 15px;
    background-color: #5cb85c;
    border: 1px solid #fff;
    border-radius: 100%;
    box-sizing: content-box;

    &:before {
      position: absolute;
      top: 1px;
      right: 2px;
      font-size: 12px;
    }
  }
}

.green-span {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-left: 8px;
}

.legend {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;

  .legend-item {
    display: flex;
    align-items: center;
    font-size: 14px;

    .status-circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 8px;
    }

    .green-circle {
      background-color: green;
    }

    .red-circle {
      background-color: red;
    }
  }
}
