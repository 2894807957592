.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;

  .login__wrapper {
    width: 100%;
    max-width: 400px;
  }

  .login__form {
    .login__form__logo {
      width: 13rem;
    }
  }
}
