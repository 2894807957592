/* CustomTable.scss */
.custom-table-container {
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 1rem;

  .table {
    margin-bottom: 0;
    thead {
      th {
        color: #333;
        border: none;
        border: 1px solid #333;
        vertical-align: baseline;
        white-space: nowrap;
        line-height: 1.2;
        white-space: pre-line;
      }
    }

    tbody {
      tr {
        border: 1px solid #333; /* Add a border to the table rows */
        border-bottom: none; /* Remove the bottom border to avoid doubling */

        &:last-child {
          border-bottom: 1px solid #333; /* Keep the last row with a bottom border */
        }

        &:hover {
          background-color: #f1f1f1; /* Optional: row hover effect */
        }

        td {
          padding: 12px;
          border-right: 1px solid #333; /* Add a column line between cells */
          border-left: none;
          vertical-align: middle;
          text-wrap: wrap;
          text-overflow: ellipsis;
          max-height: 2.4em;
          line-height: 1.2em;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          &:last-child {
            border-right: none; /* Remove the right border for the last cell */
          }
        }
      }
    }
  }
}

.table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.table-btn {
  background-image: linear-gradient(310deg, #fdb42b, #ffc65b);
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  max-height: 50px;
  cursor: pointer;
  transition: background 0.3s ease;
  &:hover {
    background-image: linear-gradient(310deg, #ffc65b, #fdb42b);
  }
}

.create_station_modal--wrapper {
  display: flex;
  flex-direction: column;
}

.create_station_modal--icon {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &__vertical {
    border: 4px solid #000;
    border-radius: 0.5rem;
    font-size: 2rem;
    width: 60px;
    height: 40px;
  }
  &__horizontal {
    @extend .create_station_modal--icon__vertical;
    width: 40px;
    height: 60px;
  }
}

.pagination {
  display: flex;
  padding: 1rem 0 0 0;
  margin: 0;

  .page-item {
    &.active {
      .page-link {
        color: #fff;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .page-link {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.table-row {
  transition: background-color 0.3s ease; /* Adds a smooth transition for the hover effect */
}

.table-row:hover {
  background-color: #f2f2f2; /* Change this color to the desired hover color */
  cursor: pointer; /* Optional: change the cursor to a pointer */
}

.profile-container {
  display: flex;
  align-items: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.custom-danger-background {
  background-color: #ffcccc !important; /* Bright Red */
}

.error-color-class {
  background-color: #fff3cd;
}
